import './skip-link-focus-fix';
import './jquery.event.move';
import './jquery.twentytwenty';
import 'script-loader!slick-carousel';
import 'script-loader!slick-lightbox';
import AOS from 'aos';
import 'script-loader!lity';

(function($) {

	$(document).ready(function() {
		
		headerEffects();
		inputEffects();
		faqBlock();
		accordionBlock();
		retouchBlock();
		teamMembersBlock();
		sidebarMenu();
		accordionShortcode();
		featuredServices();
		portfolioGallery();
		portfolioTiles();

		$(window).on('scroll', function() {
			headerEffects();
		});

		AOS.init({once: true, duration: 500});
	});


	// Header scroll effects
	function headerEffects() {
		var navbar = $('#masthead');

		var scrollPosition = $(window).scrollTop();

		if(scrollPosition > 100)
			navbar.addClass('site-header--scrolled');
		else
			navbar.removeClass('site-header--scrolled');
	}

	// Form input label effect
	function inputEffects() {
		$(".site-footer .ginput_container input[type=text], .ginput_container input[type=email], .ginput_container input[type=number], .ginput_container textarea").focus(function(){
			var label = $(this).parent().prev('.gfield_label');
			label.addClass('gfield_label--focus');
		}).blur(function(){
			if(!$(this).val()) {
				var label = $(this).parent().prev('.gfield_label');
				label.removeClass('gfield_label--focus');
			}
		})

		$(document).bind('gform_post_render', function(){
			inputEffects();
			$(".site-footer .ginput_container input[type=text], .ginput_container input[type=email], .ginput_container input[type=number], .ginput_container textarea").each(function() {
				if($(this).val()) {
					var label = $(this).parent().prev('.gfield_label');
					label.addClass('gfield_label--focus');
				}
			});
		});
	}

	// FAQ block
	function faqBlock() {
		$(".faq-block__heading").on('click', function(e) {
			e.preventDefault();

			var faq     = $(this).closest('.faq-block__faq'),
			    content = faq.find('.faq-block__content');
				
			content.slideToggle(300);
			faq.toggleClass('faq-block__faq--open');

			$('.faq-block__content').not(content).slideUp(300);
			$('.faq-block__faq').not(faq).removeClass('faq-block__faq--open');
		});
	}

	// Accordion block
	function accordionBlock() {
		$(".accordion-block__toggle, .accordion-block__header").on('click', function(e) {
			e.preventDefault();

			var accordion = $(this).closest('.accordion-block__accordion'),
				content   = accordion.find('.accordion-block__content');
				
			content.slideToggle();
			accordion.toggleClass('accordion-block__accordion--open');
		});
	}

	// Retouch block
	function retouchBlock() {
		$(".retouch-image__retouch-container").twentytwenty({
			no_overlay: true
		});
	}

	// Team members block
	function teamMembersBlock() {
		$(".team-members").each(function() {
			var slider    = $(this).find('.team-members__slider'),
				sliderNav = $(this).find('.team-members__headshots');
				
			slider.slick({ 
				speed: 300,
				autoplay: true,
				autoplaySpeed: 5000,
				nextArrow: '<button class="slick-prev slick-arrow" aria-label="Next" type="button"><i class="fa fa-arrow-right"></i></button>',
				prevArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="fa fa-arrow-left"></i></button>',
				responsive: [
					{
					  breakpoint: 767,
					  settings: {
						dots: true,
						adaptiveHeight: true
					  }
					},
				]
			});

			sliderNav.slick({
				slidesToShow: 6,
				slidesToScroll: 1,
				asNavFor: slider,
				focusOnSelect: true,
				arrows: false,
				draggable: false,
			});

			slider.on('afterChange', function(event, slick, currentSlide) {
				sliderNav.slick('slickGoTo', currentSlide);
				var currrentNavSlideElem = sliderNav.find('.slick-slide[data-slick-index="' + currentSlide + '"]');
				sliderNav.find('.slick-slide.is-active').removeClass('is-active');
				$(currrentNavSlideElem).addClass('is-active');
			});

			$(window).on('resize orientationchange', function() {
				sliderNav.slick('refresh');
			});
		});
	}


	// The primary menu sidebar
	function sidebarMenu() {
		var toggle  = $('#sidebar-toggle'),
		    content = $('#content'),
			header  = $('#masthead'),
			sidebar = $('#primary-menu');

		// Open-close lateral menu clicking on the menu icon
		toggle.on('click', function(event){
			event.preventDefault();
			
			toggle.toggleClass('site-header__menu-toggle--open');
			header.toggleClass('lateral-menu-is-open');
			content.toggleClass('lateral-menu-is-open');
			sidebar.toggleClass('lateral-menu-is-open');
		});

		// Close lateral menu clicking outside the menu itself
		content.on('click', function(event){
			toggle.removeClass('site-header__menu-toggle--open');
			header.removeClass('lateral-menu-is-open');
			content.removeClass('lateral-menu-is-open');
			sidebar.removeClass('lateral-menu-is-open');
		});

		// Submenu toggles
		sidebar.find('.menu-item-has-children').each(function( index ) {
			var menuItem = $(this),
				toggle   = $(this).children('.submenu-toggle'),
				submenu  = menuItem.children('.sub-menu');

				
			if(menuItem.hasClass('current-menu-ancestor')) {
				menuItem.addClass('menu-item--open');
			}
			
			toggle.click(function() {
				menuItem.toggleClass('menu-item--open');
				submenu.slideToggle(200, 'linear');
			});
		});
	}

	// Accordion shortcode
	function accordionShortcode() {
		$('.accordion-shortcode__title').on('click', function() {
			var accordion = $(this).parent('.accordion-shortcode'),
				content   = $(this).next('.accordion-shortcode__content');

			content.slideToggle();
			accordion.toggleClass('accordion-shortcode--open');
		});
	}

	// Featured services block
	function featuredServices() {

		var dot  = $('#featured-services-dot'),
    		path = $('#featured-services-path');

		if(path.length) {
			$(window).on('scroll', function() {
				var windowBottom = $(this).scrollTop() + $(this).height();
				var elementTop = path.offset().top + 200;
				var elementHeight = path.outerHeight();
				var percentage = (windowBottom - elementTop) / elementHeight;

				if (percentage > 0 && percentage <= 1) {
					var verticalTranslate = elementHeight * percentage;
					dot.css({transform: 'translate3d(0,' + verticalTranslate + 'px,0)'});

				} else if(percentage > 1 && percentage <= 1.1) {
					var horizTranslate = 400 * (percentage - 1 ) * 10;
					dot.css({transform: 'translate3d(' + horizTranslate + 'px,' + elementHeight + 'px,0)'});
				}
			});
		}
	}

	// Portfolio gallery block
	function portfolioGallery() {
		$(document).on('facetwp-loaded', function() {
			if(!FWP.facets['portfolio_filters'].length) {
				$('.facetwp-reset').addClass('checked');
			}

			$('.facetwp-reset').on('click', function(){
				FWP.reset();
				$(this).addClass('checked');
			});

			$('.facetwp-facet').on('click', function(){
				$('.facetwp-reset').removeClass('checked');
			});

			$(".portfolio-gallery__item").on('click', function() {
				var itemWrap     = $(this).parent('.portfolio-gallery__item-wrap'),
				    popup        = $(this).next('.portfolio-gallery__popup-wrap'),
				    popupOverlay = popup.find('.portfolio-gallery__popup-overlay'),
				    popupSlider  = popup.find('.portfolio-gallery__slider');
					
				$(document.body).append( popup.detach() );
				$(document.body).addClass('overflow-noscroll');
					
				popup.addClass('portfolio-gallery__popup-wrap--open');

				popupSlider.slick({
					lazyLoad: 'ondemand',
					speed: 300,
					autoplay: false,
					nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button"><i class="fa fa-arrow-right"></i></button>',
					prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button"><i class="fa fa-arrow-left"></i></button>',
					rows: 0,
				});

				$('.portfolio-gallery__nav-thumb').on('click', function(){
					var slideIndex = $(this).data('slide-index');
					popupSlider[0].slick.slickGoTo(slideIndex);
				});

				$('.portfolio-gallery__popup-overlay, .portfolio-gallery__popup-close').on('click', function(){
					popupSlider.slick('unslick');
					popup.removeClass('portfolio-gallery__popup-wrap--open');
					itemWrap.append( popup.detach() );
					$(document.body).removeClass('overflow-noscroll');
				});
			});

		});
	}

	// Portfolio tiles block
	function portfolioTiles() {
		$('.portfolio-tiles__container--popup').slickLightbox({
			itemSelector: '> div > a'
		});
	}

})(jQuery);